<template>
    <v-card :color="color" dark class="elevation-7">
        <div class="d-flex flex-wrap justify-space-between">
            <div>
                <v-card-title class="headline text-h6" v-text="title"></v-card-title>
                <v-card-subtitle class="ml-4 pt-1">
                    <v-icon>{{icon}}</v-icon>
                </v-card-subtitle>
            </div>
            <v-avatar class="ma-5 ml-auto text-h4" :color="color" :size="avatarSize" width="auto" tile>
                {{  value }}
                <!-- <v-chip class="ma-2 text-h3" large label text-color="white" :color="color">{{ value }}</v-chip> -->
            </v-avatar>
        </div>
    </v-card>
</template>
<script>

export default {
    name: "BoxCard",
    props: {
        color: { type: String, default: "primary" },
        title: { type: String, default: "" },
        subtitle: { type: String, default: "" },
        icon: { type: String, default: "" },
        value: { type: Number, default: 0 },
        avatarSize: { type: Number, default: 55 }
    }
}
</script>